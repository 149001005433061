import Layout from '@/components/layout'
import Swiper from '@/components/swiper'
import MoibleSwiper from '@/components/mobileCom/swiper'
import { usePage } from '@/hooks'
import Link from 'next/link'
import Image from 'next/image'
import actions from '@/services/news'
import { TYPE } from '@/constants'
import './index.less'

const init: Record<string, any> = {
  state: {},
}

const topSellingModelsMobileSwiperData: string[] = [
  'https://fed.dian.so/image/c060401bfadb7ab7d4036ae9c3740fc4.png',
  'https://fed.dian.so/image/2908ce7ecc6132d4809829d404139ce1.png',
  'https://fed.dian.so/image/367c0c3611f0ec840028f7599ce59f1b.jpg',
  'https://fed.dian.so/image/a97138236df78c0ecd41366cd23dda3e.jpg',
  'https://fed.dian.so/image/14416c96f43c2cdf9805fd514ecabe46.jpg',
  'https://fed.dian.so/image/66a327b066da2c197d95d119c57412b0.jpg',
]

Index.getInitialProps = async function (): Promise<any> {
  try {
    const position1: string = await actions.getPositionPics({ position: TYPE.SLIDER_TYPE_ENUM.HOME_PC })
    const position2: string = await actions.getPositionPics({ position: TYPE.SLIDER_TYPE_ENUM.HOME_MOBILE })
    const bannerPicSwiper: string[] = position1?.split(',')
    const bannerMobileSwiper: string[] = position2?.split(',')

    return {
      bannerPicSwiper,
      bannerMobileSwiper,
    }
  } catch (error) {
    return {
      bannerPicSwiper: [],
      bannerMobileSwiper: [],
    }
  }

}

function Index (props: any): JSX.Element {

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, events, loading, error] = usePage(init, props)
  const {
    bannerPicSwiper,
    bannerMobileSwiper,
  } = props

  return <Layout title="小电官方网站">
    <div className="index-page">
      <Swiper bImgs={bannerPicSwiper} sImgs={bannerMobileSwiper} />
      <div className="map hidden-xs">
        <div className="map-header">
          <div className="map-header-title">小电科技，共享充电创领者</div>
          <div className="map-header-text">业务覆盖全国2700座县级以上城市</div>
          <div className="map-header-text">累计注册用户达4.5亿</div>
        </div>
      </div>
      <div className="map map-xs visible-xs">
        <div className="map-header-xs">
          <div className="map-header-title-xs">小电科技，共享充电创领者</div>
          <div className="map-header-text-xs">业务覆盖全国2700座县级以上城市</div>
          <div className="map-header-text-xs">累计注册用户达4.5亿</div>
        </div>
      </div>
      <div className="join">
        {/* pc端 */}
        <img className="img-1 hidden-xs" src="https://fed.dian.so/image/a55dc0a0ef41f033dcfb61dc16e0d875.png" />
        <div className="img-2-box hidden-xs">
          <Image className="img-2" width="1100" height="196" src="https://img3.dian.so/lhc/2022/05/17/2200w_392h_ED1B11652784620.png" />
          <div className="btn-box">
            <Link href="/os/agentRecruit"><span className="btn">查看小电代理加盟业务详情</span></Link>
          </div>
        </div>
        {/* 手机端 */}
        <div className="img-2-box visible-xs">
          <Image className="img-mobile" width="375" height="313" src="https://fed.dian.so/image/6788237612e6993f18c8cb65f57b9e3a.png" />
          <div className="btn-box">
            <Link href="/os/agentRecruit"><span className="btn">查看小电代理加盟业务详情</span></Link>
          </div>
        </div>

      </div>
      <div className="advertising">
        <div className="img-1 img-cover" />
        <div className="img-2">
          <Image width="595" height="489" src="https://img3.dian.so/lhc/2022/05/17/1190w_978h_91F391652784811.png" />
        </div>
        <div style={{ marginBottom: 170 }}>
          <div className="img-3">
            <Image width="1050" height="239" src="https://img3.dian.so/lhc/2022/05/17/2100w_478h_F94A11652785035.png" />
          </div>
          <div className="btn-box">
            <Link href="/os/cooperation"><span className="btn">获取广告刊例</span></Link>
          </div>
        </div>

        <div className="hidden-xs">
          <div className="title">
            <p className="theme">小电共享充电宝</p>
            <p className="desc">多种规格独立线材输出，适配于小电已发布的所有智能充电箱机/柜机</p>
          </div>
          <div className="img-4">
            <Image width="426" height="426" src="https://fed.dian.so/image/2cc82a8e70f84326c0adbf0d43529470.png" />
          </div>
        </div>

        <div className="visible-xs">
          <div className="title">
            <p className="theme">小电共享充电宝</p>
            <p className="desc">多种规格独立线材输出，</p>
            <p className="desc">适配于小电已发布的所有智能充电箱机/柜机</p>
          </div>
          <div className="img-4">
            <Image width="316" height="316" src="https://fed.dian.so/image/2cc82a8e70f84326c0adbf0d43529470.png" />
          </div>
        </div>

        <div className="title">
          <p className="theme">热销机型展示</p>
          <p className="desc">满足不同消费场景需求，室内外兼容性高</p>
        </div>
        <div className="img-5 hidden-xs">
          <Image width="1026" height="285" src="https://fed.dian.so/image/3ad1da125d7eb3ff99b2003f225ef295.png" />
        </div>
        <div className="visible-xs" style={{ marginBottom: 50 }}>
          <MoibleSwiper
            max={topSellingModelsMobileSwiperData.length - 1} swiperData={topSellingModelsMobileSwiperData}
          />
        </div>
        <div className="title">
          <p className="theme">一键扫码 轻松借还</p>
        </div>
        <img className="img-6" src="https://img3.dian.so/lhc/2022/05/18/1114w_186h_23D201652841534.png" />
        <img className="img-7" src="https://fed.dian.so/image/2f41f8519421b5897c47d26ec6280c69.png" />
        <div className="title">
          <p className="theme">合作品牌商户</p>
        </div>
        <div className="img-8 visible-xs">
          <Image width="315" height="628" src="https://fed.dian.so/image/0e283da3e4c2ec8c59812917368f68e2.png" />
        </div>
        <div className="img-8 hidden-xs">
          <img width="891" height="392" src="https://fed.dian.so/image/7aded0f46e429c4373c1f23e3915c0e7.png" />
        </div>
      </div>

    </div>

  </Layout>
}
export default Index
